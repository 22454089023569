import "classlist-polyfill";
import "whatwg-fetch";
import "custom-event-polyfill";
import "core-js/features/array/index";
import "core-js/features/dom-collections/index";
import "core-js/features/object/index";
import "core-js/features/promise/index";
import "core-js/features/string/index";
import "core-js/features/weak-map/index";
import "core-js/features/data-view/index";
import "requestidlecallback-polyfill";
